import React, { useState, useEffect } from 'react';
import { FaRegCommentDots } from 'react-icons/fa';
import Select from 'react-select';

const projects = [
  { id: 1, name: 'Project A', tasks: ['Task A1', 'Task A2', 'Task A3'] },
  { id: 2, name: 'Project B', tasks: ['Task B1', 'Task B2'] },
  { id: 3, name: 'Project C', tasks: ['Task C1', 'Task C2', 'Task C3', 'Task C4'] },
];

// Main Progress Component
function Progress() {
  const [showOverlay, setShowOverlay] = useState(false);
  const [commentData, setCommentData] = useState(null);

  // Sample data for the table (similar content as the Progress page)
  const tableData = [
    { projectName: 'Project A', activityName: 'Task A1', date: '2023-10-01', description: 'Completed initial setup', hoursSpent: 2.5 },
    { projectName: 'Project B', activityName: 'Task B1', date: '2023-10-02', description: 'Developed main feature', hoursSpent: 1.25 },
    { projectName: 'Project C', activityName: 'Task C2', date: '2023-10-03', description: 'Debugged and fixed issues', hoursSpent: 3 },
  ];

  // Calculate the total hours spent
  const totalHours = tableData.reduce((total, item) => total + item.hoursSpent, 0);

  const formatHoursToHM = (hours) => {
    const totalMinutes = Math.floor(hours * 60);
    const hrs = Math.floor(totalMinutes / 60);
    const mins = totalMinutes % 60;
    return `${hrs}h ${mins}m`;
  };

  const handleCommentClick = (data) => {
    setCommentData(data);
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
    setCommentData(null);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center w-full max-w-2xl">
        <h1 className="text-2xl font-bold text-green-500">Attendance Submitted Successfully!</h1>
        <p className="mt-4 text-gray-700">Your attendance has been recorded.</p>
        <p className="mt-2 text-gray-500">{new Date().toLocaleDateString()}</p> {/* Display today's date */}

        {/* Table to display project data */}
        <div className="mt-6 overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300 text-left">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b-2 font-semibold">Project Name</th>
                <th className="px-4 py-2 border-b-2 font-semibold">Activity Name</th>
                <th className="px-4 py-2 border-b-2 font-semibold">Hours Spent</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2 border-r">{item.projectName}</td>
                  <td className="px-4 py-2 border-r">{item.activityName}</td>
                  <td className="px-4 py-2 flex items-center">
                    {formatHoursToHM(item.hoursSpent)} 
                    <FaRegCommentDots 
                      className="ml-2 text-blue-500 cursor-pointer"
                      onClick={() => handleCommentClick(item)}
                    />
                  </td>
                </tr>
              ))}
              {/* Total row */}
              <tr>
                <td colSpan="2" className="px-4 py-2 font-semibold text-right border-r">Total</td>
                <td className="px-4 py-2 font-semibold">{formatHoursToHM(totalHours)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Add Progress button */}
        <div className="mt-6">
          <button
            onClick={() => setShowOverlay(true)}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Add Progress
          </button>
        </div>
      </div>

      {/* Overlay/Modal for comment details */}
      {showOverlay && commentData && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
            <button
              onClick={handleCloseOverlay}
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full px-2"
            >
              ✕
            </button>
            <h2 className="text-xl font-bold mb-4">Progress Details</h2>
            <p><strong>Project Name:</strong> {commentData.projectName}</p>
            <p><strong>Activity Name:</strong> {commentData.activityName}</p>
            <p><strong>Date:</strong> {commentData.date}</p>
            <div className="mt-4">
              <label className="block text-gray-700 font-semibold mb-1">Description:</label>
              <textarea
                className="w-full px-4 py-2 border rounded-lg"
                value={commentData.description}
                readOnly
              />
            </div>
          </div>
        </div>
      )}

      {/* Overlay/Modal for Progress form */}
      {showOverlay && !commentData && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
            <button
              onClick={handleCloseOverlay}
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full px-2"
            >
              ✕
            </button>
            {/* Progress Form Component */}
            <ProgressForm onClose={handleCloseOverlay} />
          </div>
        </div>
      )}
    </div>
  );
}

// Embedded Progress Form Component
const ProgressForm = ({ onClose }) => {
  const [selectedProject, setSelectedProject] = useState('');
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState('Select a Task');
  const [description, setDescription] = useState('');
  const [date] = useState(new Date().toISOString().substring(0, 10)); // Today's date, fixed and unchangeable
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    if (selectedProject) {
      const project = projects.find((proj) => proj.name === selectedProject);
      setTasks(project ? project.tasks : []);
      setSelectedTask('Select a Task'); // Reset task when a new project is selected
    } else {
      setTasks([]);
      setSelectedTask('Select a Task'); // Ensure task is reset when no project is selected
    }
  }, [selectedProject]);

  const projectOptions = projects.map((project) => ({
    value: project.name,
    label: project.name,
  }));

  const taskOptions = [
    { value: 'Select a Task', label: 'Select a Task', isDisabled: true },
    ...tasks.map((task) => ({
      value: task,
      label: task,
    })),
  ];

  const incrementHours = () => {
    setHours((prev) => (prev < 23 ? prev + 1 : 0));
  };

  const decrementHours = () => {
    setHours((prev) => (prev > 0 ? prev - 1 : 23));
  };

  const incrementMinutes = () => {
    setMinutes((prev) => (prev < 59 ? prev + 1 : 0));
  };

  const decrementMinutes = () => {
    setMinutes((prev) => (prev > 0 ? prev - 1 : 59));
  };

  const handleSave = () => {
    alert('Event saved successfully!');
    onClose(); // Close the overlay after saving
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4 text-center">New Progress</h1>
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-1">Project</label>
        <Select
          options={projectOptions}
          onChange={(option) => setSelectedProject(option.value)}
          placeholder="Select or search a project"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-1">Task</label>
        <Select
          options={taskOptions}
          onChange={(option) => setSelectedTask(option.value)}
          value={{ value: selectedTask, label: selectedTask }}
          placeholder="Select or search a task"
          isDisabled={!selectedProject}
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-1">Description</label>
        <textarea
          className="w-full px-4 py-2 border rounded-lg"
          placeholder="Enter description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-1">Date</label>
        <input
          type="date"
          className="w-full px-4 py-2 border rounded-lg bg-gray-100 cursor-not-allowed"
          value={date}
          disabled
        />
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 font-semibold mb-1">Hours Spent</label>
        <div className="flex space-x-4">
          <div className="flex items-center space-x-2">
            <button
              onClick={decrementHours}
              className="bg-blue-200 hover:bg-blue-300 px-2 py-1 rounded"
            >
              -
            </button>
            <input
              type="text"
              className="w-12 text-center border rounded-lg"
              value={String(hours).padStart(2, '0')}
              readOnly
            />
            <button
              onClick={incrementHours}
              className="bg-blue-200 hover:bg-blue-300 px-2 py-1 rounded"
            >
              +
            </button>
            <span className="text-gray-700">Hours</span>
          </div>

          <div className="flex items-center space-x-2">
            <button
              onClick={decrementMinutes}
              className="bg-blue-200 hover:bg-blue-300 px-2 py-1 rounded"
            >
              -
            </button>
            <input
              type="text"
              className="w-12 text-center border rounded-lg"
              value={String(minutes).padStart(2, '0')}
              readOnly
            />
            <button
              onClick={incrementMinutes}
              className="bg-blue-200 hover:bg-blue-300 px-2 py-1 rounded"
            >
              +
            </button>
            <span className="text-gray-700">Minutes</span>
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-6">
        <button
          onClick={handleSave}
          className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-600"
        >
          SAVE & CLOSE
        </button>
        <button
          onClick={onClose}
          className="text-blue-500 font-bold py-2 px-4 hover:underline"
        >
          DISCARD
        </button>
      </div>
    </div>
  );
};

export default Progress;
